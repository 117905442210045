export default {
  general: {
    search: 'Szukaj',
    edit: 'Edytuj',
    confirm: 'Potwierdź',
    remove: 'Usuń',
    cancel: 'Anuluj',
    save: 'Zapisz',
    finish: 'Zakończ',
    name: 'Nazwa',
    add: 'Dodaj',
    ok: 'OK',
    details: 'Szczegóły',
    packOs: 'PackOS',
    config: 'ConfigHub',
    workboost: 'WorkBoost',
    keepup: 'KeepUp',
    goodin: 'GoodIn',
    knowlake: 'KnowLake',
    logicprint5: 'LogicPrint5',
    results: 'wyników',
    noPermissions: 'Brak uprawnień',
    plant: 'Fabryka',
    description: 'Opis',
    code: 'Kod',
    to: 'Do',
    from: 'Od',
    copiedToClipboard: 'Skopiowano do schowka',
    now: 'Teraz',
    noData: 'Brak danych',
    default: 'Domyślna',
    clone: 'Klonuj',
    general: 'Generalna',
    iWantToProceed: 'Chcę kontynuować',
    requestReceived: 'Żądanie otrzymane',
  },
  menu: {
    userSettings: 'Ustawienia konta',
    logout: 'Wyloguj',
  },
  plants: {
    name: 'Nazwa',
    code: 'Kod',
    latitude: 'Szerokość geograficzna',
    longitude: 'Długość geograficzna',
    structure: 'Struktura fabryki',
    selectPlant: 'Wybierz fabrykę',
  },
  diy: {
    setupStatuses: 'Status konfiguracji fabryki',
    database: 'Baza danych',
    appService: 'App service',
    deployment: 'Deployment',
    apiPlantsInfoUpdate: 'Dodanie informacji o nowej fabryce w API',
    enforceSetupDoneStatus: 'Zmień status konfiguracji na pomyślny',
    setupErrorMessage: 'Konfiguracja nie powiodła się. Skontaktuj się z działem supportu.',
  },
  elements: {
    area: 'Obszar',
    line: 'Linia',
    machine: 'Maszyna',
    type: 'Typ elementu',
    location: 'Lokalizacja',
    addElement: 'Dodaj linię/maszynę/obszar',
    capabilities: 'Obsługa',
    capability: {
      activities: 'Aktywności',
      downtimes: 'Przestoje',
      production: 'Produkcja',
      oee: 'OEE',
    },
  },
  auth: {
    signOut: 'Wyloguj',
  },
  pages: {
    plants: 'Fabryki',
    people: 'Ludzie',
    classifications: 'Klasyfikacje',
    products: 'Produkty',
    licenses: 'Licencje',
    settings: 'Ustawienia',
    myAccount: 'Moje konto',
    auditLog: 'Logi audytowe',
  },
  people: {
    users: 'Użytkownicy',
    roles: 'Role',
    competences: 'Kompetencje',
    noPermission: 'Brak uprawnień do zarządzania użytkownikami',
  },
  users: {
    add: 'Dodaj użytkownika',
    all: 'Wszyscy użytkownicy',
    import: 'Importuj użytkowników',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    name: 'Nazwa użytkownika',
    displayName: 'Nazwa',
    email: 'E-mail',
    phone: 'Telefon',
    companyEmployeeId: 'Firmowe ID pracownika',
    new: 'Nowy użytkownik',
    roles: 'Role',
    elementAccess: 'Dostęp do',
  },
  settings: {
    card: 'Karta',
    blockCard: 'Zablokuj kartę',
    pinChange: 'Zmień PIN',
    cardId: 'Numer karty',
    cardPin: 'PIN karty',
    oldCardPin: 'Aktualny PIN',
    newCardPin: 'Nowy PIN',
    activeSince: 'Aktywna od',
    inactiveSince: 'Nieaktywna od',
    pinLengthError: 'PIN musi mieć 4 cyfry',
    cardIdLengthError: 'Numer karty jest za krótki. Wymagana długość:',
    help: 'Pomoc',
  },
  account: {
    password: 'Hasło',
    roles: 'Role',
    passwordsNotEqual: 'Oba hasła muszą być jednakowe',
    repeatPassword: 'Powtórz hasło',
    newPassword: 'Nowe hasło',
    oldPassword: 'Aktualne hasło',
    claims: 'Uprawnienia',
    edit: 'Edycja konta',
    usersEdition: 'Edycja użytkowników',
    selfEditWarning: 'Nie możesz edytować swoich własnych uprawnień do fabryk',
    permissions: 'Uprawnienia',
    settings: 'Moje konto',
  },
  error: {
    unknown: 'Nieznany błąd',
    incorrectEmail: 'Adres e-mail powinien być w formacie example@domain.com',
    emptyInput: 'To pole nie może być puste',
    wrongInput: 'Podane dane są nieprawidłowe!',
    passwordsMustBeEqual: 'Hasła muszą być identyczne!',
    incorrectCoordinate: 'Współrzędna powinna być podana w formacie 12.34567',
    incorrectCode: 'Kod może zawierać tylko litery i cyfry oraz nie może być dłuższy niż 256 znaków',
    importNotAvailable: 'Import nie jest aktualnie możliwy. Spróbuj ponownie później.',
    jobFailed: 'Wystąpił błąd w trakcie przetwarzania pliku',
    listOfErrors: 'Lista błędów',
    tooLongString: 'Wartość pola jest zbyt długa',
  },
  competence: {
    add: 'Dodaj kompetencje',
    expiryDate: 'Data wygaśnięcia',
  },
  role: {
    add: 'Dodaj rolę',
    manager: 'Kierownik',
    new: 'Nowy rolę',
    chooseRole: 'Wybierz rolę',
  },
  permissions: {
    inheritedFrom: 'Dziedziczone z ',
    add: 'Dodaj uprawnienia',
    systemAdmin: 'Administrator systemu',
    releaseNotesAccess: 'Opis zmian',
    choosePermission: 'Wybierz uprawnienie',
    chooseTenant: 'Wybierz Tenanta',
    chooseGroup: 'Wybierz Grupę',
    choosePlant: 'Wybierz fabrykę',
    chooseAsset: 'Wybierz zasób',
    tenantAdminAccess: 'Administrator organizacji',
    tenantAuditLogAccess: 'Dostęp do logów audytowych organizacji',
    plantAuditLogAccess: 'Dostęp do logów audytowych fabryki',
    plantElementAccess: 'Dostęp do elementu fabryki',
    plantAccess: 'Dostęp do fabryki',
    changeDowntimes: 'Zmiana przestojów',
    elementAccess: 'Dostęp do elementu',
    projectCreateAccess: 'Możliwość tworzenia projektów',
    usersManagementAccess: 'Dostęp do zarządzania użytkownikami',
    projectAdminAccess: 'Administrator projektu',
    projectAccess: 'Dostęp do projektu',
    taskAdminAccess: 'Dostęp do zarządzania zadaniami',
    taskStatusAccess: 'Dostęp do akceptowania zadań',
    orderManagementAccess: 'Dostęp do zarządzania zleceniami produkcyjnymi',
    orderOperationAccess: 'Dostęp do uruchamiania zleceń produkcyjnych',
    plantAdminAccess: 'Administrator fabryki',
    writeAccess: 'Dostęp do edycji elementu i jego zleceń',
    readAccess: 'Dostęp do przeglądania elementu i jego zleceń',
    orderManagement: 'Zarządzanie zleceniami',
    orderViewAccess: 'Dostęp do wyświetlenia zleceń',
    orderCompleteManualProduction: 'Dostęp do manualnej produkcji w zakończonych zleceniach',
    orderCompleteEdit: 'Dostęp do edycji zakończonego zlecenia',
    orderInprogressEdit: 'Dostęp do edycji trwającego zlecenia',
    orderLifecycle: 'Obsługa cyklu życia zlecenia',
    orderEdit: 'Edycja zlecenia',
    materialManagement: 'Zarządzanie Master Material',
    showSkuList: 'Wyświetlanie listy SKU',
    editSkuList: 'Edytowanie grup SKU',
    statesManagement: 'Edycja stanów maszyn i reguł',
    statesEdit: 'Edycja stanów',
    addReason: 'Dodawanie przyczyny',
    addCorrectiveAction: 'Dodawanie akcji korekcyjnej',
    editProblems: 'Edycja problemów',
    reportAccess: 'Dostęp do raportów',
    targetConfig: 'Dostęp do edycji oczekiwanej wydajności i energii',
    plantConfig: 'Konfiguracja fabryki',
    configFlow: 'Edycja struktury lini i połączeń',
    elementsOrder: 'Edycja kolejności wyświetlania elementów',
    unitsEdit: 'Edycja słownika jednostek',
    configShifts: 'Edycja słownika i harmonogramu zmian',
    dayStart: 'Edycja początku dnia pracy',
    configSignals: 'Edycja sygnałów i właściowści',
    notificationEdit: 'Edycja notyfikacji',
    serviceDefinitionManagement: 'Zarządzanie definicjami serwisu',
    projectCreate: 'Tworzenie projektów',
    projectAdmin: 'Zarządzanie projektem',
    projectView: 'Wyświetlanie zadań w projekcie',
    templatesManagement: 'Zarządzanie szablonami zadań i grup zadań',
    questsCreate: 'Tworzenie zadań',
    questGroupAdmin: 'Zarządzanie zadaniami w obrębie grupy użytkowników',
    groupQuests: 'Wyświetlanie zadań przypisanych do grupy',
    manualDowntime: 'Dodawanie przestojów',
    manualProduction: 'Potwierdzanie aktualnej produkcji',
    manageDowntimes: 'Zarządzanie przestojami',
    updateSuccessfully: 'Aktualizacja uprawnień zakończona sukcesem.',
    labelling: 'Etykietowanie',
    serializationAndAggregation: 'Serializacja i agregacja',
    orders: 'Zlecenia',
    sku: 'SKU',
    labels: 'Etykiety',
    requests: 'Żądania',
    settings: 'Ustawienia',
    planning: 'Planowanie',
    production: 'Produkcja',
    archive: 'Archiwum',
    importLog: 'Logi importowe',
  },
  registration: {
    register: 'Zarejestruj',
    companyName: 'Nazwa firmy',
    companyDescription: 'Opis firmy',
    nextStep: 'Następny krok',
    companySetUp: 'Założ swoją firmę',
    profileSetUp: 'Załóż swój profil',
    create: 'Utwórz',
    successful: 'Twoja firma i profil zostały pomyślnie utworzone',
    getStarted: 'Rozpocznij',
    passwordValidation: 'Hasło powinno zawierać przynajmniej 6 znaków i dwie cyfry',
    products: 'Wybierz produkt',
    invalidEmail: 'Nieprawidłowy adres email',
    regulations: 'Akceptuję regulamin',
    tryAgain: 'Spróbuj ponownie',
    addressUrl: 'Adres Url',
  },
  keepUp: {
    workBoostIntegration: 'Integracja z WorkBoost',
    useWorkBoost: 'Używaj WorkBoosta',
    project: 'Projekt',
    type: 'Typ',
    initialState: 'Stan początkowy',
  },
  notify: {
    nothing: 'Brak powiadomień',
  },
  auditLogs: {
    tenant: 'Tenant',
    version: 'Wersja',
    timestamp: 'Data',
    type: 'Typ zdarzenia',
    event: 'Zdarzenie',
    dateRange: 'Kiedy',
    aggregateId: 'Id agregatu',
    userId: 'Id użytkownika',
    userName: 'Użytkownik',
  },
  table: {
    paginationPrevText: 'Poprzedni',
    paginationNextText: 'Następny',
  },
  licenses: {
    tenantLicenses: 'Licencje tenantów',
    machineLicenses: 'Licencje maszyn',
    line: 'Linia',
    baseAutomated: 'Maszyny Base Automated',
    advancedAutomated: 'Roszerzenia Advanced Signals',
    entryManual: 'Maszyny Manualne',
    entryDummy: 'Maszyny Dummy',
    signal: 'Rozszerzenia o 20 Sygnałów',
    vision: 'Rozszerzenia Vision',
    aggregate: 'Razem w fabryce',
    timestamp: 'Licencje maszyn na',
    year: 'Rok',
  },
  import: {
    upload: 'Upuść pliki tutaj żeby wysłać',
    notSaved: 'Nie zapisano',
    saved: 'Zapisano',
    actionRequired: 'Zweryfikuj',
    checked: 'Zweryfikowano',
    fillAndAdd: 'Wypełnij i dodaj',
    nothingImported: 'Brak poprawnych danych do importu',
    previousImports: 'Poprzednie importy',
    fileIsBeingProcessed: 'Plik jest przetwarzany',
  },
  taxonomies: {
    unableToFetch: 'Konfiguracja klasyfikacji niedostępna',
    taxonomy: 'Taksonomie',
    classification: 'Klasyfikacja',
    classifications: 'Klasyfikacje',
    defaultClassification: 'Domyślna klasyfikacja',
    defaultTaxonomy: 'Domyślna taksonomia',
    assignTo: 'Przypisz do',
    pickANode: 'Wybierz węzeł',
    nodeDisabledForSelection: 'Nie można przypisać do tego węzła',
    continueToAddNodes: 'Kontynuuj, aby dodać węzły',
    createNewClassification: 'Stwórz nową klasyfikacje',
    factoryStructure: 'Struktura fabryki',
    problems: 'Problemy',
    classificationFor: 'Klasyfikacja dla',
    whereToCloneClassification: 'Dokąd chcesz sklonować tę klasyfikację?',
    areYouSureAboutEdit: 'Czy na pewno chcesz edytować tę sekcję?',
    thisCanProblemsInElements: 'Może to powodować problemy powiązań z poziomami w sekcji węzłów.',
    doYouWantToRemoveThisClassification: 'Czy chcesz usunąć tę klasyfikację?',
    enterName: 'Podaj nazwę',
    enterDescription: 'Podaj opis',
    enterCode: 'Podaj kod',
    level: 'Poziom',
    levels: 'Poziomy',
    pickLevel: 'Wybierz poziom',
    nodeWarning: 'Musisz wybrać poziom dla węzła. Sprawdź czy ten węzeł może znajdować się pod tym poziomem',
    editLevels: 'Edytuj poziomy',
    addLevel: 'Dodaj poziom',
    nodes: 'Węzły',
    editNodes: 'Edytuj węzły',
    addNode: 'Dodaj węzeł',
    addLevelToStartEditing: 'Dodaj poziom, aby rozpocząć edycję',
    addNodeToStartEditing: 'Dodaj węzeł, aby rozpocząć edycję',
    levelLockedTooltip: 'Jeśli poziom jest zablokowany, nie można przypisać żadnych problemów ani elementów '
      + 'do węzłów w tym poziomie.',
  },
};
